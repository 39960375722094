import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VExpansionPanels, {
    staticClass: "mt-n3",
    attrs: {
      "accordion": "",
      "multiple": ""
    },
    model: {
      value: _vm.panelsOpenedByDefault,
      callback: function ($$v) {
        _vm.panelsOpenedByDefault = $$v;
      },
      expression: "panelsOpenedByDefault"
    }
  }, [_c(VContainer, {
    ref: "allgemein",
    staticClass: "ma-0 pa-0"
  }, [_c(VExpansionPanel, [_c(VExpansionPanelHeader, {
    staticClass: "primary--text text-h5 font-weight-black pl-9 pt-6"
  }, [_vm._v("Allgemeine Informationen")]), _c(VExpansionPanelContent, {
    staticClass: "py-5"
  }, [_c('GeneralinformationForm')], 1)], 1)], 1), _c(VContainer, {
    ref: "kunde",
    staticClass: "ma-0 pa-0"
  }, [_c(VExpansionPanel, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.isCompany,
      expression: "address.isCompany"
    }]
  }, [_c(VExpansionPanelHeader, {
    staticClass: "primary--text text-h5 font-weight-black pl-9 pt-6"
  }, [_vm._v(_vm._s(_vm.addressTypes.find(type => type.fldName === 'kd_kunde').fldDisplayName))]), _c(VExpansionPanelContent, {
    staticClass: "py-5"
  }, [_c('CompanyinformationForm')], 1)], 1)], 1), _c(VContainer, {
    ref: "lieferant",
    staticClass: "ma-0 pa-0"
  }, [_c(VExpansionPanel, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.isSupplier,
      expression: "address.isSupplier"
    }]
  }, [_c(VExpansionPanelHeader, {
    staticClass: "primary--text text-h5 font-weight-black pl-9 pt-6"
  }, [_vm._v(_vm._s(_vm.addressTypes.find(type => type.fldName === 'kd_lieferant').fldDisplayName))]), _c(VExpansionPanelContent, {
    staticClass: "py-5"
  }, [_c('SupplierinformationForm')], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };