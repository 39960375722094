import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VExpansionPanels, {
    staticClass: "mt-n3",
    attrs: {
      "accordion": "",
      "multiple": ""
    },
    model: {
      value: _vm.panelsOpenedByDefault,
      callback: function ($$v) {
        _vm.panelsOpenedByDefault = $$v;
      },
      expression: "panelsOpenedByDefault"
    }
  }, [_c('div', {
    ref: "allgemein"
  }), _c(VExpansionPanel, [_c(VExpansionPanelHeader, {
    staticClass: "primary--text text-h5 font-weight-black pl-9 pt-6"
  }, [_vm._v("Addressinformation")]), _c(VExpansionPanelContent, {
    staticClass: "py-5"
  }, [_c('AddressinformationForm')], 1)], 1), _c(VContainer, {
    ref: "patient",
    staticClass: "ma-0 pa-0"
  }, [_c(VExpansionPanel, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.isPatient,
      expression: "address.isPatient"
    }]
  }, [_c(VExpansionPanelHeader, {
    staticClass: "primary--text text-h5 font-weight-black pl-9 pt-6"
  }, [_vm._v(" " + _vm._s(_vm.addressTypes.find(type => type.fldName === 'kd_patient').fldDisplayName) + " ")]), _c(VExpansionPanelContent, {
    staticClass: "py-5"
  }, [_c('PatientinformationForm')], 1)], 1)], 1), _c(VExpansionPanel, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.isRelative && _vm.address.isPatient,
      expression: "address.isRelative && address.isPatient"
    }]
  }, [_c(VExpansionPanelHeader, {
    staticClass: "primary--text text-h5 font-weight-black pl-9 pt-6"
  }, [_vm._v(" Hauptversichertendaten ")]), _c(VExpansionPanelContent, {
    staticClass: "py-5"
  }, [_c('MainInsuranceDataForm')], 1)], 1), _c(VContainer, {
    ref: "verkaeufer",
    staticClass: "ma-0 pa-0"
  }, [_c(VExpansionPanel, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.isEmployee,
      expression: "address.isEmployee"
    }]
  }, [_c(VExpansionPanelHeader, {
    staticClass: "primary--text text-h5 font-weight-black pl-9 pt-6"
  }, [_vm._v(" " + _vm._s(_vm.addressTypes.find(type => type.fldName === 'kd_verkaeufer').fldDisplayName) + " ")]), _c(VExpansionPanelContent, {
    staticClass: "py-5"
  }, [_c('EmployeeinformationForm')], 1)], 1)], 1), _c(VContainer, {
    ref: "arzt",
    staticClass: "ma-0 pa-0"
  }, [_c(VExpansionPanel, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.isDoctor,
      expression: "address.isDoctor"
    }]
  }, [_c(VExpansionPanelHeader, {
    staticClass: "primary--text text-h5 font-weight-black pl-9 pt-6"
  }, [_vm._v(" " + _vm._s(_vm.addressTypes.find(type => type.fldName === 'kd_arzt').fldDisplayName) + " ")]), _c(VExpansionPanelContent, {
    staticClass: "py-5"
  }, [_c('DoctorinformationForm')], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };