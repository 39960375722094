import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
import axios from 'axios';
export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson,
      patientOptions: state => state.crmAddresses.patientOptions,
      employeeOptions: state => state.crmAddresses.employeeOptions,
      options: state => state.crmAddresses.addressOptions,
      validSVNRRule() {
        return async value => {
          // Perform SVNR validation asynchronously
          const valid = await this.checkSVNR(value);
          alert(valid);
          if (!valid) {
            return false;
          }
          return true;
        };
      }
    }),
    computedDateFormatted() {
      return this.formatDate(this.date_entry);
    }
  },
  data: () => ({
    rules: {
      onlyPositive: value => value >= 0 || value == null || value == '' || 'Wert kann nicht negativ sein!',
      emailFormat: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!'
    },
    formValidity: false,
    date_entry: null,
    dateFormatted_entry: null,
    menu: false,
    menu_exit: false,
    date_exit: false,
    dateFormatted_exit: null,
    errorMessages: [],
    testDate: '',
    testDate2: ''
  }),
  methods: {
    updateValidity(newValidity) {
      this.$store.commit('updateValidEmployee', newValidity);
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('.');
      alert('here');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    formatDate(date) {
      if (!date) return null;
      alert('there');
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    async checkSVNR() {
      let svnr = this.address.employee.svnr + '' + this.address.employee.svnrDat;
      await axios.post('v1/crm/addresses/new/checksv', null, {
        params: {
          svnr: svnr
        }
      }).then(res => {
        if (res.data) {
          this.errorMessages = [];
          return true;
        } else {
          this.errorMessages.push('SVNR ungültig!');
          return false;
        }
      }).catch(() => {
        this.errorMessages.push('SVNR ungültig!');
        return false;
      });
    }
  },
  watch: {
    date_entry() {
      this.dateFormatted_entry = this.formatDate(this.date_entry);
      this.address.employee.entryDate = this.date_entry;
    },
    date_exit() {
      this.dateFormatted_exit = this.formatDate(this.date_exit);
      this.address.employee.exitDate = this.date_exit;
    }
  }
};