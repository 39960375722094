import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VForm, {
    on: {
      "input": function ($event) {
        return _vm.updateValidity(_vm.formValidity);
      }
    },
    model: {
      value: _vm.formValidity,
      callback: function ($$v) {
        _vm.formValidity = $$v;
      },
      expression: "formValidity"
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "* Krankenkasse",
      "prepend-inner-icon": "mdi-hospital-building",
      "items": _vm.patientOptions.healthInsuranceCompanies,
      "item-text": "displayName",
      "item-value": item => item,
      "clearable": "",
      "rules": [_vm.rules.required],
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.patient.healthInsuranceCompany,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "healthInsuranceCompany", $$v);
      },
      expression: "address.patient.healthInsuranceCompany"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": 'Diabetes'
    },
    model: {
      value: _vm.address.patient.diseases.diabetes,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient.diseases, "diabetes", $$v);
      },
      expression: "address.patient.diseases.diabetes"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": 'Angiopathie'
    },
    model: {
      value: _vm.address.patient.diseases.angiopathe,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient.diseases, "angiopathe", $$v);
      },
      expression: "address.patient.diseases.angiopathe"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": 'Neuropathie'
    },
    model: {
      value: _vm.address.patient.diseases.neuropathie,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient.diseases, "neuropathie", $$v);
      },
      expression: "address.patient.diseases.neuropathie"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c(VTextarea, {
    attrs: {
      "clearable": "",
      "rows": "2",
      "label": "Allergien",
      "outlined": "",
      "append-icon": "mdi-clipboard-plus-outline"
    },
    model: {
      value: _vm.address.patient.allergies,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "allergies", $$v);
      },
      expression: "address.patient.allergies"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "* Versichertengruppe",
      "prepend-inner-icon": "mdi-tag-text",
      "items": this.patientOptions.insuredGroups,
      "item-text": item => item.description,
      "item-value": "id",
      "clearable": "",
      "rules": [_vm.rules.required],
      "auto-select-first": ""
    },
    on: {
      "change": function ($event) {
        return _vm.checkIfRelative();
      }
    },
    model: {
      value: _vm.address.patient.insuredGroup,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "insuredGroup", $$v);
      },
      expression: "address.patient.insuredGroup"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": 'Gebührenfrei'
    },
    model: {
      value: _vm.address.patient.isFeeReady,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "isFeeReady", $$v);
      },
      expression: "address.patient.isFeeReady"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "append-icon": "mdi-calendar-blank",
      "label": "Regojahr",
      "rules": [_vm.rules.onlyPositive]
    },
    model: {
      value: _vm.address.patient.regojahr,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "regojahr", $$v);
      },
      expression: "address.patient.regojahr"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "* SVNR",
      "append-icon": "mdi-card-account-details",
      "rules": [_vm.rules.required],
      "error-messages": _vm.errorMessages
    },
    on: {
      "change": function ($event) {
        return _vm.checkSVNR();
      }
    },
    model: {
      value: _vm.address.patient.svnr,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "svnr", $$v);
      },
      expression: "address.patient.svnr"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "* SVNR Dat.",
      "rules": [_vm.rules.onlyPositive, _vm.rules.required],
      "error-messages": _vm.errorMessages
    },
    on: {
      "change": function ($event) {
        return _vm.checkSVNR();
      }
    },
    model: {
      value: _vm.address.patient.svnrDat,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "svnrDat", $$v);
      },
      expression: "address.patient.svnrDat"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": "mdi-doctor",
      "label": "* Arzt",
      "items": _vm.patientOptions.familyDoctors,
      "item-text": "displayName",
      "item-value": item => item,
      "rules": [_vm.rules.required],
      "clearable": "",
      "auto-select-first": ""
    },
    on: {
      "change": function ($event) {
        return _vm.print();
      }
    },
    model: {
      value: _vm.address.patient.familyDoctor,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient, "familyDoctor", $$v);
      },
      expression: "address.patient.familyDoctor"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "readonly": "",
      "label": "HV-Nr."
    },
    model: {
      value: _vm.address.patient.familyDoctor.hvnr,
      callback: function ($$v) {
        _vm.$set(_vm.address.patient.familyDoctor, "hvnr", $$v);
      },
      expression: "address.patient.familyDoctor.hvnr"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };