import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson,
      doctorOptions: state => state.crmAddresses.doctorOptions
    })
  },
  data: () => ({
    rules: {
      onlyPositive: value => value >= 0 || value == null || value == '' || 'Wert kann nicht negativ sein!',
      emailFormat: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!',
      sixDigitNumber: value => /^\d{6}$/.test(value) || 'Bitte geben Sie eine 6-stellige Zahl ein!'
    },
    formValidity: false
  }),
  methods: {
    updateValidity(newValidity) {
      this.$store.commit('updateValidDoctor', newValidity);
    }
  }
};