import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VForm, {
    on: {
      "input": function ($event) {
        return _vm.updateValidity(_vm.formValidity);
      }
    },
    model: {
      value: _vm.formValidity,
      callback: function ($$v) {
        _vm.formValidity = $$v;
      },
      expression: "formValidity"
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "8",
      "xl": "8",
      "md": "8"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Krankenkasse",
      "prepend-inner-icon": "mdi-hospital-building",
      "items": _vm.employeeOptions.healthInsuranceCompanies,
      "item-text": "displayName",
      "item-value": item => item,
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.employee.healthInsuranceCompany,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "healthInsuranceCompany", $$v);
      },
      expression: "address.employee.healthInsuranceCompany"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "SVNR",
      "error-messages": _vm.errorMessages,
      "append-icon": "mdi-card-account-details"
    },
    on: {
      "change": function ($event) {
        return _vm.checkSVNR();
      }
    },
    model: {
      value: _vm.address.employee.svnr,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "svnr", $$v);
      },
      expression: "address.employee.svnr"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "lg": "2",
      "xl": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "SVNR Dat.",
      "error-messages": _vm.errorMessages,
      "rules": [_vm.rules.onlyPositive]
    },
    on: {
      "change": function ($event) {
        return _vm.checkSVNR();
      }
    },
    model: {
      value: _vm.address.employee.svnrDat,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "svnrDat", $$v);
      },
      expression: "address.employee.svnrDat"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4",
      "xl": "4"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Kassenmitarbeiter",
      "prepend-icon": "mdi-cash-register"
    },
    model: {
      value: _vm.address.employee.isCashier,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "isCashier", $$v);
      },
      expression: "address.employee.isCashier"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4",
      "xl": "4"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "BDE-Mitarbeiter",
      "prepend-icon": "mdi-database-edit"
    },
    model: {
      value: _vm.address.employee.isBdeEmployee,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "isBdeEmployee", $$v);
      },
      expression: "address.employee.isBdeEmployee"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4",
      "xl": "4"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Externer Mitarbeiter",
      "prepend-icon": "mdi-account-arrow-left"
    },
    model: {
      value: _vm.address.employee.isExternalEmployee,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "isExternalEmployee", $$v);
      },
      expression: "address.employee.isExternalEmployee"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Personalnummer",
      "append-icon": "mdi-numeric"
    },
    model: {
      value: _vm.address.employee.staffId,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "staffId", $$v);
      },
      expression: "address.employee.staffId"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Ausweisnummer",
      "append-icon": "mdi-id-card"
    },
    model: {
      value: _vm.address.employee.idNumber,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "idNumber", $$v);
      },
      expression: "address.employee.idNumber"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "4",
      "lg": "4"
    }
  }, [_c(VMenu, {
    ref: "menu",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.date_entry,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    on: {
      "update:returnValue": function ($event) {
        _vm.date_entry = $event;
      },
      "update:return-value": function ($event) {
        _vm.date_entry = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        attrs
      }) {
        return [_c(VTextField, _vm._b({
          attrs: {
            "outlined": "",
            "label": "Eintrittsdatum",
            "append-icon": "mdi-calendar-import"
          },
          on: {
            "click:append": function ($event) {
              _vm.menu = true;
            },
            "blur": function ($event) {
              _vm.date_entry = _vm.parseDate(_vm.dateFormatted_entry);
            }
          },
          model: {
            value: _vm.dateFormatted_entry,
            callback: function ($$v) {
              _vm.dateFormatted_entry = $$v;
            },
            expression: "dateFormatted_entry"
          }
        }, 'v-text-field', attrs, false))];
      }
    }]),
    model: {
      value: _vm.menu,
      callback: function ($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c(VDatePicker, {
    attrs: {
      "no-title": "",
      "color": "primary",
      "scrollable": ""
    },
    model: {
      value: _vm.date_entry,
      callback: function ($$v) {
        _vm.date_entry = $$v;
      },
      expression: "date_entry"
    }
  }, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.menu = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.menu.save(_vm.date_entry);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "4",
      "lg": "4"
    }
  }, [_c(VMenu, {
    ref: "menu_exit",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.date_exit,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    on: {
      "update:returnValue": function ($event) {
        _vm.date_exit = $event;
      },
      "update:return-value": function ($event) {
        _vm.date_exit = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        attrs
      }) {
        return [_c(VTextField, _vm._b({
          attrs: {
            "outlined": "",
            "label": "Austrittsdatum",
            "append-icon": "mdi-calendar-export"
          },
          on: {
            "click:append": function ($event) {
              _vm.menu_exit = true;
            },
            "blur": function ($event) {
              _vm.date_exit = _vm.parseDate(_vm.dateFormatted_exit);
            }
          },
          model: {
            value: _vm.dateFormatted_exit,
            callback: function ($$v) {
              _vm.dateFormatted_exit = $$v;
            },
            expression: "dateFormatted_exit"
          }
        }, 'v-text-field', attrs, false))];
      }
    }]),
    model: {
      value: _vm.menu_exit,
      callback: function ($$v) {
        _vm.menu_exit = $$v;
      },
      expression: "menu_exit"
    }
  }, [_c(VDatePicker, {
    attrs: {
      "no-title": "",
      "color": "primary",
      "scrollable": ""
    },
    model: {
      value: _vm.date_exit,
      callback: function ($$v) {
        _vm.date_exit = $$v;
      },
      expression: "date_exit"
    }
  }, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.menu_exit = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.menu_exit.save(_vm.date_exit);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "xl": "4",
      "md": "4"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "prepend-inner-icon": "mdi-account",
      "label": "Loginname",
      "outlined": "",
      "clearable": "",
      "items": _vm.employeeOptions.activeUsers,
      "item-text": "username",
      "item-value": item => item.userId,
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.employee.userId,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "userId", $$v);
      },
      expression: "address.employee.userId"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "clearable": "",
      "prepend-inner-icon": "mdi-warehouse",
      "label": "Lager",
      "items": _vm.employeeOptions.warehouses,
      "item-text": "description",
      "item-value": item => item,
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.employee.warehouse,
      callback: function ($$v) {
        _vm.$set(_vm.address.employee, "warehouse", $$v);
      },
      expression: "address.employee.warehouse"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };