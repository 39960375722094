import { mapState } from 'vuex';
import AddressinformationForm from '@/components/crm/addresses/addAddress/addPerson/AddressinformationForm.vue';
import PatientinformationForm from '@/components/crm/addresses/addAddress/addPerson/PatientinformationForm.vue';
import MainInsuranceDataForm from '@/components/crm/addresses/addAddress/addPerson/MainInsuranceDataForm.vue';
import EmployeeinformationForm from '@/components/crm/addresses/addAddress/addPerson/EmployeeinformationForm.vue';
import DoctorinformationForm from '@/components/crm/addresses/addAddress/addPerson/DoctorinformationForm.vue';
export default {
  components: {
    AddressinformationForm,
    PatientinformationForm,
    MainInsuranceDataForm,
    EmployeeinformationForm,
    DoctorinformationForm
  },
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson,
      patientOptions: state => state.crmAddresses.patientOptions,
      addressTypes: state => state.crmAddresses.addressTypes
    })
  },
  data: () => ({
    panelsOpenedByDefault: [0, 1, 2, 3, 4]
  }),
  methods: {
    loadAddressTypes() {
      this.$store.dispatch('loadAddressTypes').then(() => {
        this.$store.commit('updatePersonNavList');
        this.$store.commit('updateNonPersonNavList');
      });
    }
  },
  mounted() {
    this.loadAddressTypes();
  }
};