import { mapState } from 'vuex';
import DuplicateFoundDialog from '@/components/crm/addresses/addAddress/DuplicateFoundDialog.vue';
import WarningDialog from '@/components/core/WarningDialog.vue';
import axios from 'axios';
export default {
  components: {
    DuplicateFoundDialog,
    WarningDialog
  },
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson,
      patientOptions: state => state.crmAddresses.patientOptions,
      validAddress: state => state.crmAddresses.validAddress,
      options: state => state.crmAddresses.addressOptions,
      duplicatePersons: state => state.crmAddresses.duplicates.persons,
      addressTypes: state => state.crmAddresses.addressTypes
    }),
    computedDateFormatted() {
      return this.formatDate(this.date);
    }
  },
  data: () => ({
    rules: {
      onlyPositive: value => value >= 0 || value == null || value == '' || 'Wert kann nicht negativ sein!',
      emailFormat: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!',
      onlyNumbers: value => /^[0-9]*$/.test(value) || 'Nur Zahlen sind erlaubt!'
    },
    date: null,
    dateFormatted: null,
    menu: false,
    formValidity: false,
    streetSuggestions: [],
    titleSuggestions: []
  }),
  beforeUpdate() {
    //default country = austria (last item (ö))
    const lastItem = this.options.countries[this.options.countries.length - 1];
    if (lastItem) {
      this.address.address.country = lastItem.id;
    }
  },
  methods: {
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split('.');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    loadPatientDefaults() {
      if (this.address.isPatient) {
        //Get Select Options
        this.$store.dispatch('loadPatientOptions').then(() => {
          //get Doctors
          this.$store.dispatch('loadPatientFamilyDoctors', '');
        });
      }
    },
    loadEmployeeDefaults() {
      if (this.address.isEmployee) {
        this.$store.dispatch('loadEmployeeOptions');
      }
    },
    loadDoctorDefaults() {
      if (this.address.isDoctor) {
        this.$store.dispatch('loadDoctorOptions');
      }
    },
    checkIfDuplicate() {
      //TODO: check for duplicate
      if (this.duplicatePersons == null && this.address.lastname != '' && this.address.firstname != '' && (this.address.dateOfBirth != '' || this.address.address.zip != '' && this.address.address.city != '' && this.address.address.street != '')) {
        // TODO: NO 500 when no duplicate found!
        // TODO: also return DOB of found person
        let duplicateCheckObject = {
          salutation: this.address.salutation,
          title: this.address.title,
          firstname: this.address.firstname,
          lastname: this.address.lastname,
          dateOfBirth: this.address.dateOfBirth,
          address: {
            zip: this.address.address.zip,
            city: this.address.address.city,
            street: this.address.address.street,
            country: this.address.address.country
          }
        };
        this.$store.dispatch('checkDuplicatePersons', duplicateCheckObject).then(() => {
          //NO ERROR -> duplicate found
          if (this.duplicatePersons != []) {
            this.$refs.warning.open('Folgende Adresse existiert bereits: \n' + `${this.duplicatePersons[0].displayName}.`, [{
              action: 'compare',
              text: 'Vergleichen und Fortfahren'
            }]);
          }
        });
      }
    },
    autofillCity() {
      if (this.address.address.country != null && (this.address.address.zip != null || this.address.address.zip != '')) {
        console.log(this.address.address);
        axios.post('v1/crm/addresses/new/city', {
          plz: this.address.address.zip,
          country: this.address.address.country
        }).then(res => {
          this.address.address.city = res.data;
        });
      }
    },
    autofillGender() {
      axios.get('v1/crm/addresses/new/gender', {
        params: {
          name: this.address.firstname
        }
      }).then(res => {
        this.address.gender = res.data.key;
        this.updateSalutation();
      });
    },
    autofillTitle() {
      axios.get('v1/crm/addresses/new/title', {
        params: {
          q: this.address.title
        }
      }).then(res => {
        console.log(res);
        this.titleSuggestions = res.data;
      });
    },
    updateStreetSuggestions() {
      axios.post('v1/crm/addresses/new/street', {
        plz: this.address.address.zip,
        query: this.address.address.street
      }).then(res => {
        console.log(res);
        this.streetSuggestions = res.data.streets;
      });
    },
    warningActionPerformed() {
      let comparedAddress = {
        name: this.address.lastname + ' ' + this.address.firstname,
        zip: this.address.address.zip,
        city: this.address.address.city,
        street: this.address.address.street
      };
      this.$refs.duplicate.open(comparedAddress, this.duplicatePersons[0]);
    },
    updateSalutation() {
      //Find the correct gender from options and extract the correct anrede

      if (this.address.gender == null) {
        this.address.salutation = '';
        return;
      }
      this.address.salutation = this.options.genders.find(item => item.key.lfdnr == this.address.gender.lfdnr).anrede;
    },
    updateSVNRDat() {
      var date = new Date(this.address.dateOfBirth);
      let month = date.getMonth() + 1; //needed because get month returns index
      var svnrDat = date.getDate().toString().padStart(5, '0').slice(-2) + month.toString().padStart(5, '0').slice(-2) + date.getFullYear().toString().slice(-2);
      this.address.patient.svnrDat = svnrDat;
    },
    updateValidity(newValidity) {
      this.$store.commit('updateValidAddress', newValidity);
    },
    updatePersonNavList() {
      this.$store.commit('updatePersonNavList');
    }
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
      this.address.dateOfBirth = this.date;
    }
  }
};