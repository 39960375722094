import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newNonPerson,
      supplierOptions: state => state.crmAddresses.supplierOptions,
      options: state => state.crmAddresses.addressOptions
    })
  },
  data: () => ({
    rules: {
      onlyPositive: value => value >= 0 || value == null || value == '' || 'Wert kann nicht negativ sein!',
      emailFormat: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!',
      onlyNumbers: value => value == '' || value == null || /^-?\d{1,}(?:,\d+)?$/.test(value) || 'Nur Zahlen sind erlaubt!'
    },
    date: null,
    menu: false,
    formValidity: true
  }),
  methods: {
    updateValidity(newValidity) {
      this.$store.commit('updateValidSupplier', newValidity);
    }
  }
};