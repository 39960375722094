import { mapState } from 'vuex';
export default {
  components: {},
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newNonPerson,
      companyOptions: state => state.crmAddresses.companyOptions,
      options: state => state.crmAddresses.addressOptions
    })
  },
  data: () => ({
    rules: {
      onlyPositive: value => value >= 0 || value == null || value == '' || 'Wert kann nicht negativ sein!',
      emailFormat: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!',
      onlyNumbers: value => value == '' || value == null || /^-?\d*\.?\d*$/.test(value) || 'Nur Zahlen sind erlaubt!'
    },
    formValidity: true,
    testValue: ''
  }),
  methods: {
    updateValidity(newValidity) {
      console.log(newValidity + '\n');
      this.$store.commit('updateValidCompany', newValidity);
    }
  }
};